import ContentComponents from "../components/ContentComponents/ContentComponents";
import ArrayUtil from '../Utilities/ArrayUtil';
import Cookies from 'universal-cookie';

import GenericResponses from './genericResponses';

class BotResponseApi {

  constructor(){
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

    this.apiRootUrl = 'https://api.intro.im';
    if(isLocalhost){
      //this.apiRootUrl = "http://localhost:8080";
      console.log("Using localhost as API");
      this.apiRootUrl = "http://" + window.location.hostname + ":8080";
    }

    this.siteName = 'lukas';

    // Suggestion logic in getResponse()
    this.usedSuggestions = [];
    this.currentGenericSuggestions = [];
    this.currentGenericSuggestions = this.getGenericSuggestions(3);
    this.oneTimeSuggestions = [];

    // Session handeling
    this.cookies = new Cookies();

    let cookieSession = this.cookies.get('apiSessionKey');
    this._sessionKey = cookieSession ? cookieSession : null;
    this.getSessionKey();

  }

  async getJsonFromUrl(url){
    try {
      let response = await (await fetch(url)).json();
      return response;
    }
    catch(e){
      return null;
    }
  }

  // Returns a promise, as it needs to fetch a session key
  async urlBuilder(endpoint, params){
    let baseUrl = this.apiRootUrl + '/' + this.siteName;
    // https://stackoverflow.com/a/50436226/1200698
    const buildURLQuery = obj =>
      Object.entries(obj)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');

    if(endpoint !== 'newSession'){
      params.session = await this.getSessionKey();
    }

    return baseUrl + '/' + endpoint + '?' + buildURLQuery(params);
  }

  async getSessionKey(){
    if(this._sessionKey !== null){ // key is set
      return this._sessionKey;
    }
    else{ // Key is not set
      let url = await this.urlBuilder('newSession', {});
      let response = await this.getJsonFromUrl(url);
      if(response === null || typeof response.sessionKey !== 'string'){
        return null;
      }
      this._sessionKey = response.sessionKey;
      this.cookies.set('apiSessionKey', this._sessionKey, {
        path: '/',
        maxAge: 60*60 // 1 hour
      });
      return this._sessionKey;
    }
  }

  messageIncludes(message, search){
    return (message.toLowerCase()).includes(search.toLowerCase());
  }

  // get a list of suggested questions
  getAllGenericSuggestions(){
    return GenericResponses;
  }

  // Gets some suggestions that are always applicaple
  // Will return less than n, if there is not enough available
  getGenericSuggestions(n){
    let availableSuggestions = this.getAllGenericSuggestions();
    let allChosenSuggestions = availableSuggestions.filter(s => !this.usedSuggestions.includes(s));
    let chosenSuggestions = ArrayUtil.getFirstElements(allChosenSuggestions, n);
    // Add the suggestions to used suggestions:
    chosenSuggestions.forEach(s => {
      this.usedSuggestions.push(s);
    })
    return chosenSuggestions;
  }

  // Remove the suggestion if used, and replace with a new
  removeACurrentSuggestions(message){
    if(this.currentGenericSuggestions.includes(message)){
      ArrayUtil.removeItem(this.currentGenericSuggestions, message);
      let newSuggestion = this.getGenericSuggestions(1);
      if(newSuggestion[0]){
        this.currentGenericSuggestions.push(newSuggestion[0]);
      }
    }

    return this.getCurrentSuggestions();
  }

  getCurrentSuggestions(){
    if(typeof this.oneTimeSuggestions[0] === "string"){
      return this.oneTimeSuggestions;
    }
    return this.currentGenericSuggestions;
  }

  generateResponseObject(
  messages, contentComponent, suggestions = [],
  contentComponentIsImportant = true,
  contentComponentDescription = 'Se supplerende indhold',  ){
    return {
      messages: messages,
      contentComponent: contentComponent,
      suggestions: suggestions,
      contentComponentIsImportant: contentComponentIsImportant,
      contentComponentDescription: contentComponentDescription
    };
  }

  getFailResponse(){
    return this.generateResponseObject(["Jeg tror desværre der skete en fejl..."], ContentComponents["Contact"]);
  }

  async getResponse(userMessage){
    /* Ask server */
    let url = await this.urlBuilder('getBotResponse', {q: userMessage});
    let response = await this.getJsonFromUrl(url);

    if(response === null){
      return this.getFailResponse();
    }

    /* Suggestion logic: */
    let suggestions = response.suggestions;

    if(!suggestions){
      suggestions = [];
    }

    if(typeof suggestions[0] === "string"){
      this.oneTimeSuggestions = suggestions.slice();
    }
    else {
      this.oneTimeSuggestions = [];
    }


    /* Return a valid result */
    let contentComponent = ContentComponents[response.contentComponent];
    let contentComponentIsImportant = false;
    if(response.contentComponentIsImportant){
      contentComponentIsImportant = true;
    }

    if(typeof response.messages[0] === "string" && typeof contentComponent === "function") {
      return this.generateResponseObject(
        response.messages,
        contentComponent,
        suggestions,
        contentComponentIsImportant,
        (response.contentComponentDescription || 'Se supplerende indhold')
      );
    }
    else {
      console.log("API Response not valid: ", response);
      return this.getFailResponse();
    }
  }
}


export default BotResponseApi;