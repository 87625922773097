import React from 'react';
import BotResponseApi from '../BotResponseApi/BotResponseApi';
import UrlUtil from '../Utilities/UrlUtil';

// Components used:
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import ContentComponents from './ContentComponents/ContentComponents';

class MainSite extends React.Component {
  constructor(props){
    super(props);
    this.botApi = new BotResponseApi();
    this.state = {
      messages: this.initialMessages(),
      currentContentComponent: ContentComponents["Welcome"],
      currentSuggestions: this.botApi.getCurrentSuggestions(),
      showMainContentOnMobile: false // Switch between Sidebar and MainContent on mobile
    };

  }

  // The messages that is there to begin with
  initialMessages(){
    return [
      {
        sender: 'computer',
        message: 'Hej! Jeg er en AI, der kan fortælle dig om Lukas. Hvad vil du vide?',
        contentComponent: ContentComponents['Welcome']
      }
    ];
  }

  async onMessageSend(me, message, startOnContentComponent = false){ // me = this MainSite instance
    // Validate message
    if(typeof message !== "string" || message.trim() === ""){// Non-empty message given
      console.log("Invalid message: ", message);
      return false;
    }

    // Save the user message
    me.state.messages.push({
      sender: 'user',
      message: message,
      contentComponent: null
    });

    // remove the message if it was a suggestion
    this.botApi.removeACurrentSuggestions(message);

    // Show the Sidebar after a message has been sent
    // so the user can see the response
    this.setState({
      showMainContentOnMobile: startOnContentComponent
    });

    me.forceUpdate();

    // Ask the bot for a response
    let response = await this.botApi.getResponse(message);
    // save the bot response
    if(response.messages){
      response.messages.forEach(text => {
        me.state.messages.push({
          sender: 'computer',
          message: text,
          contentComponent: response.contentComponent,
          contentComponentIsImportant: response.contentComponentIsImportant,
          contentComponentDescription: response.contentComponentDescription
        });
      });
    }

    // Update the suggestions
    this.setState({
      currentSuggestions: this.botApi.getCurrentSuggestions()
    });

    // Show the content component
    this.setState({ currentContentComponent: response.contentComponent});
    me.forceUpdate();

  }

  mobileTogleView(){
    this.setState({
      showMainContentOnMobile: !this.state.showMainContentOnMobile
    })
  }

  componentDidMount(){
    let startOnContentComponent = false;

    if(UrlUtil.findGetParameter('startOnContent') !== null){
      startOnContentComponent = true;
    }

    // Load a message from the URL and send it
    let messageFromUrl = UrlUtil.pathnameToSentence(window.location.pathname);
    if(messageFromUrl !== ''){
      this.onMessageSend(this, messageFromUrl, startOnContentComponent);
      UrlUtil.changeUrl('/');
    }
  }

  render() {
    return (
      <div className='MainSite' id='MainSite'>
        <Sidebar
          onMessageSend={msg => this.onMessageSend(this, msg)}
          messages={this.state.messages}
          suggestions={this.state.currentSuggestions}
          mobileTogleView={() => this.mobileTogleView()}
          hideOnMobile={this.state.showMainContentOnMobile}
        />
        <MainContent
          contentComponent={this.state.currentContentComponent}
          onMessageSend={msg => this.onMessageSend(this, msg)}
          mobileTogleView={() => this.mobileTogleView()}
          hideOnMobile={!this.state.showMainContentOnMobile}
        />
      </div>
    );
  }
}


export default MainSite;