import React from 'react';


class CvEntry extends React.Component {

  static defaultProps = {
    title: "",
    startTime: "",
    endTime: "nu",
    description: ""
  }

  render() {
    let description = this.props.description ? (<div className='description'>{this.props.description}</div>) : '';

    let time = '';
    if(this.props.startTime){
      time = this.props.startTime;
    }
    if(this.props.endTime){
      time = time + ' - ' + this.props.endTime;
    }
    time = time !== '' ? (<div className='time'>{time}</div>) : '';

    return (
      <div className='CvEntry'>
        <div className='title'>{this.props.title}</div>
        {time}
        {description}
      </div>
    );
  }
}


export default CvEntry;