import React from 'react';


class ToggleViewBar extends React.Component {
  render() {
    return (
      <div
        className='ToggleViewBar'
        onClick={this.props.mobileTogleView}
      >
        <center>
          <span role="img" aria-label="speech-bubble">💬</span>
          Tilbage til chatbot
          </center>
      </div>
    );
  }
}


export default ToggleViewBar;