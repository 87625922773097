import React from 'react';
import ContentComponent from '../ContentComponent';

// Images:
import ImageInMalaga from './InMalaga.jpg';

class AboutMe extends ContentComponent {
  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'AboutMe',
      removePadding: false
    }
  }

  renderContent() {
    return (
      <div>
        <h1>Et billede af mig i solen</h1>
        <img
          src={ImageInMalaga}
          alt='Lukas in Malaga'
          style={
            {
              width: '400px',
              maxWidth: '100%'
            }
          }
        />
        <p>
          {this.renderChatLink("Det ser fedt ud!")}
        </p>
      </div>
    );
  }
}


export default AboutMe;