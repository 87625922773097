import React from 'react';
// https://github.com/xiaolin/react-image-gallery
// import ImageGallery from 'react-image-gallery';


class PortfolioItem extends React.Component {

  renderImages(){
    return this.props.images.map((path, key) => {
      let image = require('./img/' + path);
      return (
        <div className='img-wrapper' key={key}>
          <a
            href={image}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={image}
              alt=''
            />
          </a>
        </div>
      );
    });
  }

  render() {

    return (
      <div className='PortfolioItem'>
        <div className='description'>
          <h3>{this.props.title}</h3>
          {this.props.description}
        </div>
        <div className='images'>
          {this.renderImages()}
        </div>
      </div>
    );
  }
}


export default PortfolioItem;