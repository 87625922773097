import React from 'react';
import ContentComponent from '../ContentComponent';

// Images:
import ImagePortrait from './portrait.png';


class Welcome extends ContentComponent {
  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'Welcome',
      removePadding: false
    }
  }

  renderContent() {
    return (
      <div>
        <h1>Velkomen til mit virtuelle CV!</h1>
        <p>
          Jeg har udviklet denne bot til at kunne fortælle lidt om mig.<br />
          Du kan for eksempel spørge mig: <br />
          {this.renderChatLink("Hvem er du?")} <br />
          {this.renderChatLink("Fortæl om dine tidligere projekter")} <br />
          {this.renderChatLink("Hvordan kontakter jeg dig?")}
        </p>
        <p>
          <img
            src={ImagePortrait}
            alt='Portrait of Lukas'
            className='portrait'
          />
        </p>
        <p>
          <a target="_blank" rel="noopener noreferrer" href="https://intro.im/privacy/">
            Læs privatlivspolitikken
          </a>
        </p>
      </div>
    );
  }
}


export default Welcome;
