import React from 'react';
import ContentComponent from '../ContentComponent';


class Contact extends ContentComponent {

  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'Contact',
      removePadding: false
    }
  }

  renderContent() {
    return (
      <div>
        <h1>Træt af at tale med en AI?</h1>
        <b>Du kan finde mig på:</b>
        <ul>
          <li>Mobil: <a href='tel:+4530237034'>+45 30 23 70 34</a></li>
          <li>Email: <a href='mailto:lukas@lukasmig.com'>lukas@lukasmig.com</a></li>
          <li>
            <a
              href='https://www.linkedin.com/in/lukasfrimertholander/'
              target='_blank'
              rel='noopener noreferrer'
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              href='https://github.com/LukasFT/'
              target='_blank'
              rel='noopener noreferrer'
            >
              GitHub
            </a>
          </li>
        </ul>
        Jeg bor i København, men har ikke noget mod at rejse. CVR 38469436
      </div>
    );
  }
}


export default Contact;