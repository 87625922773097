// Components used:
import Welcome from './Welcome/Welcome';
import Empty from './Empty/Empty';
import Cv from './Cv/Cv';
import Contact from './Contact/Contact';
import AboutMe from './AboutMe/AboutMe';
import Portfolio from './Portfolio/Portfolio';
import PersonalStrengths from './PersonalStrengths/PersonalStrengths';
import FavoriteTechnologies from './FavoriteTechnologies/FavoriteTechnologies';
import Prices from './Prices/Prices';

const ContentComponents = {
  Welcome,
  Empty,
  Cv,
  Contact,
  AboutMe,
  Portfolio,
  PersonalStrengths,
  FavoriteTechnologies,
  Prices
};

export default ContentComponents;