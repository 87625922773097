import React from 'react';

// Used components:
import ChatInput from './ChatInput';
import Conversation from './Conversation';

class ChatUI extends React.Component {
  render() {
    return (
      <div className='ChatUI'>
        <Conversation
          messages={this.props.messages}
          mobileTogleView={this.props.mobileTogleView}
        />
        <ChatInput
          suggestions={this.props.suggestions}
          onMessageSend={this.props.onMessageSend}
        />
      </div>
    );
  }
}


export default ChatUI;