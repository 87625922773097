import React from 'react';

import ChatLink from '../Chat/ChatLink';

class ContentComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {test: ''};

    /*
    // Set this in an implementation:
    this.settings = {
      className: 'Something',
      removePadding: false
    }
    */
  }

  // Render a ChatLink
  renderChatLink(message){
    return (
      <ChatLink onMessageSend={this.props.onMessageSend}>
        {message}
      </ChatLink>
    );
  }

  scrollToTop(){
    this.topOfPage.scrollIntoView({ behavior: 'auto' });
  }

  componentDidMount() {
    this.scrollToTop();
  }

  componentDidUpdate() {
    this.scrollToTop();
  }

  render() {
    let paddingExtraClass = this.settings.removePadding ? ' no-padding' : '';
    return (
      <div className={'ContentComponent-outer' + paddingExtraClass}>
        <span ref={(el) => { this.topOfPage = el; }}></span>
        <div className={this.settings.className + ' ContentComponent'}>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent(){ // Override this method
    return (
      <div>
        <b>Replace content</b>
      </div>
    );
  }
}


export default ContentComponent;