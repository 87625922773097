import React from 'react';
import ContentComponent from '../ContentComponent';

// Images:
import ImageNode from './img/node.png';
import ImageWordPress from './img/wordpress.png';
import ImageReact from './img/react.png';
import ImageJavascript from './img/javascript.png';
import ImageHtml5 from './img/html5.png';
import ImageCss3 from './img/css3.png';
import ImagePhp from './img/php.png';
import ImageFsharp from './img/fsharp.png';
import ImageCsharp from './img/csharp.png';

class FavoriteTechnologies extends ContentComponent {
  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'FavoriteTechnologies',
      removePadding: false
    }
  }

  renderImage(imgSrc, alt){
    return (
      <img
        src={imgSrc}
        alt={alt}
        title={alt}
      />
    );
  }

  renderContent() {
    return (
      <div>
        <center>
          <h1>Teknologier</h1>
          {this.renderImage(ImageNode, 'Node.js')}
          {this.renderImage(ImageReact, 'React')}
          {this.renderImage(ImageJavascript, 'JavaScript')}
          {this.renderImage(ImageWordPress, 'WordPress')}
          {this.renderImage(ImageHtml5, 'HTML5')}
          {this.renderImage(ImageCss3, 'CSS3')}
          {this.renderImage(ImagePhp, 'PHP')}
          {this.renderImage(ImageFsharp, 'F#')}
          {this.renderImage(ImageCsharp, 'C#')}
        </center>
      </div>
    );
  }
}


export default FavoriteTechnologies;