import React from 'react';

// Components used:
import ChatUI from './Chat/ChatUI';


class Sidebar extends React.Component {
  render() {
    const addMobileHideClass = (this.props.hideOnMobile) ? 'Sidebar hideOnMobile' : 'Sidebar notHideOnMobile';
    return (
      <div className={addMobileHideClass}>
        <div className="inner-container">
          <ChatUI
            onMessageSend={this.props.onMessageSend}
            messages={this.props.messages}
            suggestions={this.props.suggestions}
            mobileTogleView={this.props.mobileTogleView}
          />
        </div>
      </div>
    );
  }
}


export default Sidebar;