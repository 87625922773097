import React from 'react';
import ContentComponent from '../ContentComponent';


// Video:
import VideoDollarsMp4 from './dollars.mp4';
import VideoDollarsOgv from './dollars.ogv';
import VideoDollarsWebm from './dollars.webm';


class Prices extends ContentComponent {

  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'Prices',
      removePadding: true
    }
  }

  renderContent() {
    return (
      <div>
        <div className='video-outer'>
          <div className='video-container'>
            <video autoPlay muted loop>
              <source src={VideoDollarsMp4} type="video/mp4" />
              <source src={VideoDollarsOgv} type="video/ogg" />
              <source src={VideoDollarsWebm} type="video/webm" />
            </video>
          </div>
        </div>
      </div>
    );
  }
}


export default Prices;