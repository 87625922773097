import React from 'react';
// import BotResponseApi from '../../BotResponseApi/BotResponseApi';
// import ArrayUtil from '../../Utilities/ArrayUtil';

// Used components:
import ChatSuggestion from './ChatSuggestion';


class ChatInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      message: '', // The current typed in message
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMessageSend = this.handleMessageSend.bind(this);
  }

  // Will change the state of the component when the user types
  handleInputChange(event){
    this.setState({message: event.target.value});
  }

  // Will use the property onMessageSend() to handle the message
  handleMessageSend(event){
    event.preventDefault();
    this.props.onMessageSend(this.state.message);
    event.target.reset();
  }

  componentDidMount(){
    this.msgInput.focus(); // Will focus the input field when the site loads
  }

  handleSuggestionSelect(text){
    this.props.onMessageSend(text); // Just send the message
  }


  // Will get some suggestions that have not been used
  getSuggestions(){
    return this.props.suggestions.map((text, key) => {
      return this.renderSuggestion(key, text);
    });
  }

  // Will render a single suggestion
  renderSuggestion(key, text){
    return (
      <ChatSuggestion
        key={key}
        text={text}
        onSelect={() => this.handleSuggestionSelect(text)}
      />
    );
  }

  render() {
    const suggestionComponents = this.getSuggestions();

    return (
      <div className='ChatInput'>
        <div className='suggestions'>
          {suggestionComponents}
        </div>
        <form onSubmit={this.handleMessageSend}>
          <input
            value={this.state.value}
            onChange={this.handleInputChange}
            ref={input => { this.msgInput = input; }}
          ></input>
          <button type="submit" className='sendButton'>Send</button>
        </form>
      </div>
    );
  }
}


export default ChatInput;