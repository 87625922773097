import React from 'react';
import ContentComponent from '../ContentComponent';

// Images:
import ImageHal9000 from './hal9000.png';

// Used when no content about the message is to be displayed
class Empty extends ContentComponent {
  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'Empty',
      removePadding: false
    }
  }

  renderContent() {
    return (
      <div>
        <div className='text'>
          Du kan spørge mig om alt...
        </div>
        <div className='HalImage'>
          <img
            src={ImageHal9000}
            alt='Hal9000'
          />
        </div>
        <div className='text'>
          <center>... det er bare ikke sikkert jeg kender svaret.</center><br />
          {this.renderChatLink("Jeg er træt af at tale med en AI, giv mig den rigtige Lukas")}
        </div>
      </div>
    );
  }
}


export default Empty;