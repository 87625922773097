import React from 'react';
import ContentComponent from '../ContentComponent';


class PersonalStrengths extends ContentComponent {

  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'PersonalStrengths',
      removePadding: false
    }
  }

  renderContent() {
    return (
      <div>
        <h2>Personlige styrker</h2>
        <ul>
          <li>Kreativ og idérig</li>
          <li>Målrettet</li>
          <li>Selvstændig og initiativtagende</li>
          <li>Positiv indstillet for feedback og nye idéer</li>
          <li>Teamplayer</li>
          <li>Fleksibel og pragmatisk anlagt</li>
          <li>Serviceminded</li>
        </ul>
        <h2>Erhvervskompetencer</h2>
        <ul>
          <li>All-around hjemmesideudvikling</li>
          <li>Digital vejledning og rådgivning</li>
          <li>Programmering af mindre systemer og skræddersyede løsninger</li>
          <li>Guidance til outsourcing af opgaver med internationale freelance-portaler</li>
        </ul>
        {this.renderChatLink('Hvilke programmeringssprog er du skarpest i?')}
      </div>
    );
  }
}


export default PersonalStrengths;