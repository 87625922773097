import React from 'react';

// Components used:
import ToggleViewBar from './ToggleViewBar';

class MainContent extends React.Component {

  render() {
    const TheContent = this.props.contentComponent;

    const addMobileHideClass = (this.props.hideOnMobile) ? 'hideOnMobile' : 'notHideOnMobile';
    const mainClass = 'MainContent ' + addMobileHideClass;

    return (
      <div className={mainClass}>
        <ToggleViewBar
          mobileTogleView={this.props.mobileTogleView}
        />
        <TheContent
          onMessageSend={this.props.onMessageSend}
        ></TheContent>
      </div>
    );
  }
}


export default MainContent;