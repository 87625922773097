import TextUtil from './TextUtil';

class UrlUtil {
  // Transform '/hello-world' or '/hello%20word' to 'hello world'
  // '/' will become ''
  static pathnameToSentence(pathname){
    let s = pathname.substring(1);
    let decoded = decodeURIComponent(s);
    let text = decoded.replace(/-/g, ' ');
    return TextUtil.firstLetterUpper(text);
  }

  static getBaseUrl(url){ //https://stackoverflow.com/a/27778372/1200698
    return /^.*\//.exec(url);
  }

  // https://stackoverflow.com/a/5448595/1200698
  static findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  static changeUrl(newUrl){
    window.history.pushState(null, null, newUrl);
  }
}


export default UrlUtil;