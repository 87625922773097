import React from 'react';

// Used components:

class ChatBubble extends React.Component {
  /*componentDidMount(){
    console.log("New chat bubble: ", this.props.message)
  }*/

  render() {
    return (
      <div className='ChatBubble' data-sender={this.props.sender}>
        <div className='message'>
          {this.props.message}
        </div>
      </div>
    );
  }
}


export default ChatBubble;