class ArrayUtil {
  // Helper function: get n random items from an array
  // Note: if n is larger than the array length, the whole array is returned (with less than n items)
  static getRandomItems(arr, n) {
    // Modified from https://stackoverflow.com/a/19270021
    let result = new Array(n);
    let len = arr.length;
    let taken = new Array(len);

    if (n > len){
      return arr;
    }

    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  // Remove an item if it exists.
  static removeItem(arr, item){
    let i = arr.indexOf(item);
    if(i !== -1) {
      arr.splice(i, 1);
    }
  }

  static getFirstElements(arr, n){
    return arr.slice(0, n);
  }

}


export default ArrayUtil;