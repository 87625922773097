import React from 'react';

// Used components:

class ChatLink extends React.Component {
  render() {
    return (
      <span
        className='ChatLink'
        onClick={() => this.props.onMessageSend(this.props.children)}
      >
        <span role="img" aria-label="speech-bubble">💬</span> {this.props.children}
      </span>
    );
  }
}


export default ChatLink;