import React from 'react';
import ReactDOM from 'react-dom';
import MainSite from './components/MainSite';
// Css:
import './css/reset.css';
import './css/index.css';
import './css/chat.css';
import './css/fonts.css';
import './css/layout.css';
import './css/contentComponents.css';

const e = React.createElement;

const domContainer = document.querySelector('#root');
ReactDOM.render(e(MainSite), domContainer);