import React from 'react';
import ContentComponent from '../ContentComponent';


// Used components:
import CvEntry from './CvEntry';

class Cv extends ContentComponent {

  constructor(props){
    super(props);

    // Used by ContentComponent class
    this.settings = {
      className: 'Cv',
      removeMargin: false
    }
  }

  renderCvButton(){
    return (
      <a
        href='/cv.pdf'
        className='cv-button'
        target='blank'
        rel='noopener noreferrer'
      >
        Se mit CV som pdf
      </a>
    );
  }

  renderContent() {
    return (
      <div className='CV'>
        <h1>Mit CV</h1>
        {this.renderCvButton()}
        <div className='entries'>
          <section>
            <h3>Uddannelse</h3>
            <CvEntry
              startTime='2019'
              title='Københavns Universitet'
              description='Datalogi'
            />
            <CvEntry
              startTime='2015'
              endTime='2018'
              title='Høje-Taastrup Gymnasium'
              description='Naturvidenskabelig linje - Matematik, Fysik & Kemi'
            />
          </section>
          <section>
            <h3>Erhvervserfaring</h3>
            <CvEntry
              startTime='2021'
              title='Institut for Cellulær og Molekylær Medicin (KU)'
              description='Udvikling og projektstyring af websystem til undervisningsbrug'
            />
            <CvEntry
              startTime='2014'
              title='Freelancer Lukas Frimer Tholander'
              description='Webdesigner og digital vejleder'
            />
            <CvEntry
              startTime='2017'
              endTime='2020'
              title='Webbureauet VICTR'
              description='Leadudvikler og projektleder'
            />
            <CvEntry
              startTime='2018'
              endTime='2019'
              title='Dåstrup Skole'
              description='Lærervikar'
            />
            <CvEntry
              startTime='2015'
              endTime='2016'
              title='Dagli’Brugsen Osted'
              description='Servicemedarbejder'
            />
          </section>
          <section>
            <h3>Frivillig erfaring</h3>
            <CvEntry
              startTime='2015'
              title='Hjalte Gruppe, Det Danske Spejderkorps'
              description='Spejderleder seniorerne og for børn på 2. og 3. skoleår'
            />
            <CvEntry
              startTime='2015'
              endTime='2020'
              title='Madbod, Roskilde Festival'
              description='Tilberedning og håndtering af mad • Rengøring'
            />
            <CvEntry
              startTime='2014'
              endTime='2017'
              title='Adventure Gruppen, Det Danske Spejderkorps'
              description='Spejderleder seniorerne og for børn på 2. og 3. skoleår'
            />
          </section>
          <section>
            <h3>Kurser</h3>
            <CvEntry
              startTime='2017, 2018'
              endTime={null}
              title='Uddannelsesmarked, Det Danske Spejderkorps'
              description='Naturvejledning • Lederskab • Teamwork og idéudførsel'
            />
            <CvEntry
              startTime='2013'
              endTime={null}
              title='Forlev, Det Danske Spejderkorps'
              description='Teamwork • Kreativitet • Planlægningsmetoder'
            />
            <CvEntry
              startTime='2013'
              endTime={null}
              title='PLan Assenbæk, Det Danske Spejderkorps'
              description='Projektplanlægning • Ledelse • Teamwork'
            />
          </section>
          <section>
            <h3>Vil du vide mere?</h3>
            {this.renderChatLink('Hvilke projekter har du været involveret i?')} <br />
            {this.renderChatLink('Hvilke IT-kompetencer har du?')} <br />
            {this.renderChatLink('Hvad er dine stærke sider?')} <br />
            {this.renderChatLink('Hvem er du?')}
          </section>
        </div>
        <p>
          <a
            href='https://www.linkedin.com/in/lukasfrimertholander/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Se også min LinkedIn
          </a>
        </p>
      </div>
    );
  }
}


export default Cv;