import React from 'react';

class ChatSuggestion extends React.Component {
  render() {
    return (
      <button
        className='ChatSuggestion'
        onClick={this.props.onSelect}
      >
        {this.props.text}
      </button>
    );
  }
}


export default ChatSuggestion;