const gernericResponses = [
  'Hvem er du?',
  'Fortæl om dine tidligere projekter',
  'Hvordan kontakter jeg dig?',
  'Må jeg se dit CV?',
  'Hvilke teknologier er du glad for?',
  'Hvor meget koster en hjemmeside?',
  'Hvad er dine fritidsinteresser?',
  'Hvad er din yndlingsfilm?'
];


export default gernericResponses;