import React from 'react';

// Used components:
import ChatBubble from './ChatBubble';

class Conversation extends React.Component {
  scrollToBottom(){
    this.endOfMessages.scrollIntoView({ behavior: 'auto' });
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }


  renderMessage(key, sender, message){
    return (
      <ChatBubble
        key={key}
        sender={sender}
        message={message}
      />
    );
  }

  renderMobileReadMore(){
    let lastMessage = this.props.messages[this.props.messages.length - 1];
    if(lastMessage.sender === "user" || !lastMessage.contentComponentIsImportant){
      return (<span></span>);
    }
    else {
      return (
        <span
          className='mobileReadMore'
          onClick={ this.props.mobileTogleView }
        >
          <ChatBubble
            key={9999}
            sender={'computer'}
            message={'→ ' + lastMessage.contentComponentDescription}
          />
        </span>
      );
    }

  }

  render() {
    const messages = this.props.messages.map((msgObject, key) => this.renderMessage(key, msgObject.sender, msgObject.message));
    return (
      <div className='Conversation'>
        {messages}
        {this.renderMobileReadMore()}
        <span ref={(el) => { this.endOfMessages = el; }}></span>
      </div>
    );
  }
}


export default Conversation;