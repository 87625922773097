import React from 'react';
import ContentComponent from '../ContentComponent';

// Content:
import PortfolioItems from './PortfolioItems';

// Used components:
import PortfolioItem from './PortfolioItem';

class Portfolio extends ContentComponent {

  constructor(props){
    super(props);

    this.state = {
      currentItem: 0
    };

    // Used by ContentComponent class
    this.settings = {
      className: 'Portfolio',
      removePadding: true
    }
  }

  renderPortfolioItem(){
    let item = PortfolioItems.items[this.state.currentItem];
    return (
      <PortfolioItem
        key={this.state.currentItem}
        images={item.images}
        description={item.description}
        title={item.title}
      />
    );
  }

  prevItem(me){
    let max = PortfolioItems.items.length - 1;
    let newItem = me.state.currentItem - 1;

    if(newItem < 0){
      newItem = max;
    }

    me.setState({
      currentItem: newItem
    });
  }

  nextItem(me){
    let max = PortfolioItems.items.length - 1;
    let newItem = me.state.currentItem + 1;

    if(newItem > max){
      newItem = 0;
    }

    me.setState({
      currentItem: newItem
    });
  }

  renderContent() {
    return (
      <div>
        <h1>Portefølje</h1>
        <div className='prev' onClick={() => this.prevItem(this)}>&#8249;</div>
        <div className='next' onClick={() => this.nextItem(this)}>&#8250;</div>
        {this.renderPortfolioItem()}
      </div>
    );
  }
}


export default Portfolio;